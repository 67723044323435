import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { URI } from "../../domain/constant";
import { useGetCart, useRemoveFromCart } from "../../shared/hooks/UseCart";

function CartMenu() {
  const [isopencart, setIsopencart] = useState(false);
  const [cart, total] = useGetCart();
  const [removeFromCart] = useRemoveFromCart();

  // console.log(cart);
  return (
    <div className="cart_link">
      <a
        onClick={() => {
          setIsopencart(true);
        }}
      >
        <i className="ion-android-cart" />
        <span className="cart_text_quantity"> ₹{total}</span>
        <i className="fa fa-angle-down" />
      </a>
      <span className="cart_quantity"> {cart && cart.length} </span>
      {/*mini cart*/}
      <div className={isopencart ? "mini_cart active" : "mini_cart "}>
        <div className="cart_close">
          <div className="cart_text">
            <h3>cart</h3>
          </div>
          <div className="mini_cart_close">
            <a>
              <i
                className="ion-android-close"
                onClick={() => {
                  setIsopencart(false);
                }}
              />
            </a>
          </div>
        </div>
        <div style={{ height: "50vh", overflowY: "scroll" }}>
          {cart &&
            cart.map((item) => {
              return (
                <div className="cart_item">
                  <div className="cart_img">
                    <a href="#">
                      <img src={`${URI}${item.product.image}`} alt />
                    </a>
                  </div>
                  <div className="cart_info">
                    <a href="#">{item.product.name}</a>
                    <span className="quantity">Qty: {item.quantity}</span>
                    <span className="price_cart">
                      ₹{item.sale_price * item.quantity}
                    </span>
                  </div>
                  <div className="cart_remove">
                    <a onClick={() => removeFromCart(item.slug)}>
                      <i className="ion-android-close" />
                    </a>
                  </div>
                </div>
              );
            })}
        </div>
        {cart && cart.length == 0 && (
          <div className="text-center">
            <img src={`/images/cart-empty.jpeg`} style={{ width: "200px" }} />
            <p>
              <strong> Your cart is empty </strong>
            </p>
            <p>
              {" "}
              Looks like you have not added anything to your cart. <br /> Go
              ahead and explore products.
            </p>
            <p style={{ marginBottom: "30px" }}>
              {" "}
              <Link to="/" className="button">
                {" "}
                Go To Home
              </Link>
            </p>
          </div>
        )}
        {cart && cart.length > 0 && (
          <>
            <div className="cart_total">
              <span>Subtotal:</span>
              <span> ₹{total} </span>
            </div>
            <div className="cart_total">
              <span>Delivery Charges:</span>
              <span> ₹{60} </span>
            </div>
            <div className="cart_total">
              <span>Total:</span>
              <span> ₹{total + 60} </span>
            </div>
            <div className="mini_cart_footer">
              <div className="cart_button view_cart">
                <Link to="/cart">View cart</Link>
              </div>
              <div className="cart_button checkout">
                <Link to="/checkout" className="active">
                  Checkout
                </Link>
              </div>
            </div>
          </>
        )}
      </div>
      {/*mini cart end*/}
    </div>
  );
}

export default CartMenu;
