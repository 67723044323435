import React, { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelectAllCategory } from "../../shared/hooks/UseCategory";
import CartMenu from "../cart/CartMenu";
import Menu from "./Menu";

function Header() {
  const [submenu, setsubmenu] = useState(false);
  const [isopencart, setIsopencart] = useState(false);
  const [isopensidebar, setIsopensidebar] = useState(false);
  const [categorydata] = useSelectAllCategory();
  const { all_categorys, all_categorys_loading } = categorydata;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [window.location.pathname]);
  console.log(window.location.pathname);
  return (
    <div>
      <div
        className={
          isopensidebar
            ? "off_canvars_overlay active "
            : "off_canvars_overlay  "
        }
      />
      <div className="Offcanvas_menu Offcanvas_two offcanvas_three">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="canvas_open">
                <a
                  href="javascript:void(0)"
                  onClick={() => {
                    setIsopensidebar(true);
                  }}
                >
                  <i className="ion-navicon" />
                </a>
              </div>

              <div
                className={
                  isopensidebar
                    ? "Offcanvas_menu_wrapper active "
                    : "Offcanvas_menu_wrapper  "
                }
              >
                <div className="canvas_close">
                  <a
                    href="javascript:void(0)"
                    className="close"
                    onClick={() => {
                      setIsopensidebar(false);
                    }}
                  >
                    <i className="ion-android-close" />
                  </a>
                </div>
                {/* <div className="welcome_text">
                  <p>
                    Free shipping on all domestic orders with coupon code
                    <span>“Watches2018”</span>
                  </p>
                </div> */}

                <div id="menu" className="text-left mobile-logo">
                  <img src="/assets/img/logo/logo.png" />
                  <hr></hr>
                  <ul className="offcanvas_main_menu">
                    <li className="menu-item-has-children active">
                      <Link href="/">Home</Link>
                    </li>
                    <li className="menu-item-has-children">
                      <Link to="/our-story">Our Story </Link>
                    </li>
                    <li className="menu-item-has-children">
                      <Link to="/shop">New Arrivals </Link>
                    </li>

                    <li
                      className="menu-item-has-children"
                      onClick={() => {
                        setsubmenu(!submenu);
                      }}
                    >
                      <a href="#">
                        Shop
                        {submenu ? (
                          <i className="fa fa-angle-down"></i>
                        ) : (
                          <i className="fa fa-angle-right"></i>
                        )}
                      </a>
                      <ul
                        className="sub-menu"
                        style={{ display: submenu ? "block" : "none" }}
                      >
                        <li>
                          <Link to="/shop">Shop All</Link>
                        </li>
                        {all_categorys &&
                          all_categorys.map((category) => {
                            return (
                              <li>
                                <Link to={`/products/${category.slug}`}>
                                  {category.name}
                                </Link>
                              </li>
                            );
                          })}
                      </ul>
                    </li>
                    <li className="menu-item-has-children">
                      <Link to="/shop">Celeberity’s choice </Link>
                    </li>
                    <li className="menu-item-has-children">
                      <Link to="/contact-us">Contact Us </Link>
                    </li>
                  
                  </ul>
                </div>
                <hr></hr>
                <div className="Offcanvas_footer">
                  <span>
                    <a href="#">
                      <i className="fa fa-envelope-o" />
                      &nbsp; officialsehari@gmail.com
                    </a>
                  </span>
                  <ul>
                    <li className="facebook">
                      <a
                        href="https://www.facebook.com/sehariofficial.in"
                        target="_blank"
                      >
                        <i className="fa fa-facebook" />
                        Facebook
                      </a>
                    </li>
                    <li className="twitter">
                      <a
                        href=" https://www.linkedin.com/in/sehari-jewellery-60ab60239/"
                        target="_blank"
                      >
                        <i className="fa fa-linkedin" />
                        Linkedin
                      </a>
                    </li>
                    <li className="pinterest">
                      <a
                        href="https://www.instagram.com/sehariofficial.in/"
                        target="_blank"
                      >
                        <i className="fa fa-instagram" />
                        Instagram
                      </a>
                    </li>
                    <li className="google-plus">
                      <a
                        href="https://www.youtube.com/channel/UCnuvrY0GrMbLjtO5VrDAVNw"
                        target="_blank"
                      >
                        <i className="fa fa-youtube" />
                        Youtube
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="header_sidebar_area">
        <div className="sidebar_logo">
          <Link to="/">
            <img src="/assets/img/logo/logo.png" alt />
          </Link>
        </div>
        <div className="header_sidebar_menu">
          <div className="main_menu">
            <nav>
              <Menu />
            </nav>
          </div>
        </div>

        <div className="sidebar_form">
          <form action="#">
            <input placeholder="Email address.." type="text" />
            <button type="submit">
              <i className="ion-android-send" />
            </button>
          </form>
        </div>
        <div className="social_icone">
          <ul>
            <li>
              <a
                href="https://www.facebook.com/sehariofficial.in"
                target="_blank"
              >
                <i className="ion-social-facebook" />
              </a>
            </li>
            <li>
              <a
                href=" https://www.linkedin.com/in/sehari-jewellery-60ab60239/"
                target="_blank"
              >
                <i className="ion-social-twitter" />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/sehariofficial.in/"
                target="_blank"
              >
                <i className="fa fa-instagram" />
              </a>
            </li>

            <li>
              <a
                href="https://www.youtube.com/channel/UCnuvrY0GrMbLjtO5VrDAVNw"
                target="_blank"
              >
                <i className="ion-social-youtube" />
              </a>
            </li>
          </ul>
        </div>
      </div>

      <header className="header_area header_two">
        <div className="header_middel">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-2 col-md-2">
                <div className="logo">
                  <Link to="/">
                    <img src="/assets/img/logo/footer_logo.png" alt />
                  </Link>
                </div>
              </div>
              <div className="col-md-7">
                <div className="search_btn mobail_none2">
                  <form action="shop">
                    <input
                      placeholder="Search product..."
                      name="search[name]"
                      type="text"
                      style={{borderRadius:5}}
                    />
                    <button type="submit">
                      <i className="ion-ios-search-strong" />
                    </button>
                  </form>
                </div>
              </div>
              <div className="col-lg-3 col-md-3">
                <div className="middel_right mobile-menu" style={{background:"#fff",borderRadius:5}}>
                  <div>
                    <Link to="/track-order">Track Order</Link>
                  </div>
                  <CartMenu />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mobile-only">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div>
                  <form action="shop searchh" style={{marginBottom:20}}>
                    <div className="input-group">
                      <input
                        className="form-control"
                        name="search[name]"
                        placeholder="Type keyword to search..."
                      ></input>
                      <button
                        className="btn btn-outline-secondary"
                        type="submit"
                      >
                        <i className="fa fa-search"></i>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="header_bottom sticky-header">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12">
                <div className="main_menu_inner">
                  <div className="logo_sticky">
                    <a href="index.html">
                      <img src="assets/img/logo/logo.png" alt />
                    </a>
                  </div>
                  <div className="main_menu">
                    <nav>
                      <Menu categorydata={all_categorys} />
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="marque">
        <marquee width="100%" direction="right">
          🤍 Most affordable indian jewellery brand 🤍
        </marquee>
      </div>
    </div>
  );
}

export default Header;
