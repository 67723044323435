import React from 'react';
import { Link } from 'react-router-dom';

import { URI } from '../../domain/constant';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import CartComponent from '../cart/CartComponent';
const ProductCard = ({ product, loading }) => {
  return (
    <div className="custom-col-5">
      {!loading ? (
        product && (
          <div className="single_product">
            <div className="product_thumb">
              <Link className="primary_img" to={`/product/${product.slug}`}>
                <img src={`${URI}${product.image}`} alt />
              </Link>
              {product.gallery && product.gallery.length > 0 ? (
                <Link className="secondary_img" to={`/product/${product.slug}`}>
                  <img src={`${URI}${product.gallery[0].image}`} alt />
                </Link>
              ) : (
                <Link className="secondary_img" to={`/product/${product.slug}`}>
                  <img src={`${URI}${product.image}`} alt />
                </Link>
              )}
            </div>
            <div className="product_content">
              <div className="tag_cate">
                {product.categories &&
                  product.categories.map((item) => {
                    return (
                      <Link
                        to={`products/${item.category && item.category.slug}`}
                      >
                        {item.category && item.category.name}
                      </Link>
                    );
                  })}
              </div>
              <h3>
                <Link to={`/product/${product.slug}`}>{product.name}</Link>
              </h3>
              <div className="price_box">
                <span className="old_price">₹{product.regular_price}</span>
                <span className="current_price">₹{product.sale_price}</span>
              </div>

              <div className="homepage_cart">
                {product.is_in_stock ? (
                  <CartComponent product={product} />
                ) : (
                  <h4> Out of Stock </h4>
                )}
              </div>
              <div className="product_hover">
                <div className="product_desc">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: product.short_description,
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        )
      ) : (
        <div className="single_product">
          <Skeleton height={'280px'} />
          <Skeleton height={'12px'} />
          <Skeleton height={'16px'} />
          <hr />
          <Skeleton height={'24px'} />
        </div>
      )}
    </div>
  );
};

export default ProductCard;
