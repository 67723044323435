import React from 'react';
import Breadcrum from '../../components/breadcrum/Breadcrum';
import Footer from '../../components/common/Footer';
import Header from '../../components/common/Header';

function TermsAndConditions() {
  return (
    <div>
      <Header />
      <Breadcrum title={'Terms And Condition'} />
      <div className="privacy_policy_main_area">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="privacy_content section_1">
                <h2>Terms of use</h2>
                <p>
                  This below is an electronic record in terms of Information
                  Technology Act, 2000 and rules there under as applicable and
                  the amended provisions pertaining to electronic records in
                  various statutes as amended by the Information Technology Act,
                  2000. This electronic record is generated by a computer system
                  and does not require any physical or digital signatures
                  related to this.
                </p>
                <p>
                  Here we welcome you to Sehari, The website and brand is owned
                  by Ms. Muskan B hatia, having office at Pitampura , Delhi
                  (India).
                </p>
                <hr></hr>
                <h2>TERMS & CONDITIONS</h2>
                <hr></hr>
                <h3> Copyright and Intellectual Property</h3>
                <p>
                  Every information displayed, transmitted or carried on
                  sehariofficial.in is protected by copyright and other
                  intellectual property laws. This website is designed, updated
                  and maintained independently by this brand only. No one cannot
                  modify, publish, transmit, transfer, sell, regenerate, create
                  derivative work from, distribute, perform, display or in any
                  way commercially exploit any of the content at
                  sehariofficial.in
                </p>
                <h3>Terms of Use – Disclaimer</h3>
                <p>
                  sehariofficial.in reserves the right, in its sole discretion,
                  to suspend or cancel the service at any time if a computer
                  virus, bug, or other technical problem corrupts the security,
                  or proper administration of the service. Our website disclaims
                  all warranties or conditions, whether expressed or implied,
                  (including without limitation implied, warranties or
                  conditions of information and context). We always consider
                  ourselves and intend to be subject to the jurisdiction only of
                  the local courts. Sajanabyshagun.com reserves the right to
                  refuse service to anyone at any time. Our website
                  sajanabyshagun.com shall not be responsible for any damage
                  caused by the use of its website. The term “We”, “Us”, “Our”
                  shall mean sajanabyshagun.com
                </p>
                <h3>Privacy Policy</h3>
                <p>
                  Here at sehariofficial.in, Our responsibility is to respect
                  your privacy as much as you always do. We never share your
                  personal information with anyone. Your provided information is
                  only used by sehariofficial.in and our logistic partners to
                  get the service done as requested by you. Also this includes
                  informing you of promotional offers on a regular basis.
                  Anytime you can mark yourself inactive to stop receiving the
                  emails; however the information shared shall remain archived
                  in our servers.
                </p>
                <p>
                  This policy is effective from 1st August 2022. We may change
                  our policy by the time we update the page. Please check our
                  page from time to time to make sure that you agree and are
                  happy with our changes.
                </p>
                <h3>What inputs we need</h3>
                <p>Your Name</p>
                <p>Contact Information</p>
                <p>
                  Your Demographic Information i.e. city, pincode, state etc.
                </p>
                <p>
                  Other Information related to surveys and promotional offers.
                </p>
                <h3>What we do with you inputs</h3>
                <p>To maintain our internal records.</p>
                <p>
                  To understand better your requirements and to improve our
                  services.
                </p>
                <p>
                  Also we may send some of our promotional emails related to new
                  launches and offers or any other information which we think is
                  related to you using the email which has been provided by you.
                </p>
                <p>
                  We may also use your data to connect you for market research
                  work. For this we can connect you via email or phone. After
                  receiving the inputs we may customize our website according to
                  that only.
                </p>
                <h3>Security</h3>
                <p>
                  We assure you that your information is secure. For any
                  disclosure and unauthorised access, our setup is synchronizing
                  with suitable physical, electronic and managerial procedures
                  to safeguard and secure the information we get online.
                </p>
                <h3>How Cookies work</h3>
                <p>
                  We use traffic log cookies to identify which pages are being
                  used. That is helping us to analyse the data about web traffic
                  and also help us to improve our website as customer needs.
                </p>
                <p>
                  You can always choose to accept or decline cookies. Most of
                  the web browsers automatically accept the cookies, but you can
                  modify your browser setting to reject/decline cookies if you
                  prefer. This may disturb you for getting full advantage of the
                  website.
                </p>
                <h3>Restricting your personal data</h3>
                <p>
                  You can choose to restrict to use of your personal information
                  in the following steps:
                </p>
                <p>
                  When you are asked to fill-up a form on a website, check the
                  box that you can click and agree that you do not want any of
                  your information to be used by anyone for marketing purposes.
                </p>
                <p>
                  Previously, If you agreed for using your personal information
                  for marketing purposes then you may change your it any time by
                  writing us or emailing us on info@sajanabyshagun.com
                </p>
                <p>
                  We will not sell, distribute or lease your personal
                  information to third parties unless we have your permission or
                  are required by law to do so. We may use your personal
                  information to send you promotional information about third
                  parties which we think you may find interesting if you tell us
                  that you wish this to happen.
                </p>
                <p>
                  You may request details of personal information which we hold
                  about you under the Data Protection Act 1998. A small fee will
                  be payable. If you would like a copy of the information held
                  on you please write to us.
                </p>
                <p>
                  If you believe that any information we are holding on you is
                  incorrect or incomplete, please write to or email us as soon
                  as possible, at the above address. We will promptly correct
                  any information found to be incorrect.
                </p>
                <hr></hr>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default TermsAndConditions;
