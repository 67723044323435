import React from 'react'
import { Link } from 'react-router-dom'

function Menu({ categorydata }) {
  return (
    <ul>
      <li className="active">
        <Link to="/">Home </Link>
      </li>
      <li className="active">
        <Link to="/our-story">Our Story </Link>
      </li>
      <li className="active">
        <Link to="/shop">New Arrivals </Link>
      </li>
      <li>
        <a href="#">
          Shop <i className="fa fa-angle-down" />
        </a>
        <ul className="sub_menu pages">
          <li>
            <Link to="/shop">Shop All</Link>
          </li>
          {categorydata &&
            categorydata.map((category) => {
              return (
                <li>
                  <Link to={`/products/${category.slug}`}>{category.name}</Link>
                </li>
              )
            })}
        </ul>
      </li>
      <li className="menu-item-has-children">
                      <Link to="/shop">Celeberity’s choice </Link>
                    </li>
      <li>
        <Link to="/contact-us">Contact Us </Link>
      </li>
    
    </ul>
  )
}

export default Menu
