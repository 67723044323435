import React, { useState } from "react";
import { useApplyCoupon } from "../../shared/hooks/UseCoupon";

function CouponSection() {
  const [data, applyCoupon, discount, error] = useApplyCoupon();
  const { coupon_loading, coupon, coupon_error } = data;
  console.log(error, "COUPON");
  const [couponInput, setCoupon] = useState(null);

  return (
    <div className="coupon_code left">
      <h3>Coupon</h3>
      {discount && discount > 0 ? (
        <div className="coupon_inner">
          <p>Coupon Applied Successfully</p>
        </div>
      ) : (
        <>
          <div className="coupon_inner">
            <p>Enter your coupon code if you have one.</p>
            <input
              placeholder="Coupon code"
              type="text"
              value={couponInput}
              onChange={(e) => setCoupon(e.target.value)}
            />

            <button onClick={() => applyCoupon(couponInput)}>
              {" "}
              {coupon_loading ? "Loading ..." : "Apply coupon"}
            </button>
            {(error || coupon_error) && (
              <p className="text-danger"> Not Valid Coupon </p>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default CouponSection;
