import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import Breadcrum from '../../components/breadcrum/Breadcrum';
import Footer from '../../components/common/Footer';
import Header from '../../components/common/Header';
import * as Yup from 'yup';
import { CheckBox, SelectBox, TextInput } from '../../components/Form/Form';
import { useGetCart } from '../../shared/hooks/UseCart';
import { useApplyCoupon } from '../../shared/hooks/UseCoupon';
import CouponSection from '../../components/coupon/CouponSection';
import { convertToFormData } from '../../shared/upload';
import { Link, useHistory } from 'react-router-dom';
import { useCreateOrder } from '../../shared/hooks/UseOrder';
import api from '../../domain/api';
import axios from 'axios';

const Checkout = () => {
  let history = useHistory();

  const [cart, total] = useGetCart();
  const [AddData, addData] = useCreateOrder();

  const [data, applyCoupon, discount, error] = useApplyCoupon();
  const [featuredImage, setFeaturedImage] = useState(null);
  const [paymentOption, setPaymentOption] = useState('RAZORPAY');

  const [login, setlogin] = useState(false);
  const [coupan, setcoupan] = useState(false);

  const [orderPlaced, setOrderPlaced] = useState(false);
  console.log('CART', cart);
  const placeOrder = async (values) => {
    if (paymentOption === 'CASH_ON_DELIVERY') {
      values.discount = discount;
      values.cart = cart;
      values.sub_total = total;
      values.delivery_charges = 50;
      values.is_paid = false;
      values.order_date = new Date();
      values.payment_method = 'PREPAID';

      values.total = total + 60 - discount;
      const newFormData = convertToFormData({ values, featuredImage });
      const createdOrder = await addData(newFormData);
      localStorage.removeItem('SEHARI_CART');
      setOrderPlaced(false);
      // history.push("/order-placed");
      history.push(`/thank-you`);
    }
    if (paymentOption === 'RAZORPAY') {
      values.discount = discount;
      values.cart = cart;
      values.sub_total = total;
      values.delivery_charges = 60;
      values.is_paid = false;
      values.order_date = new Date();
      values.payment_method = 'PREPAID';
      values.total = total + 60 - discount;
      const newFormData = convertToFormData({ values, featuredImage });
      const createdOrder = await api.post('/orders', newFormData);

      const order = await axios.get(
        `https://sehariofficial.in/razorpay/pay.php?amount=${values.total}`
      );

      var options = {
        key: 'rzp_live_2zaVUdKGxGhEYK', // Enter the Key ID generated from the Dashboard
        amount: values.total * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: 'INR',
        name: 'Sehari',
        description: '',
        image: 'https://sehariofficial.in/assets/img/logo/logo.png',
        order_id: order.data, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        handler: async function (response) {
          // const formData = {
          //   address: address,
          //   customer: customer,
          //   payment_option: paymentOption,
          //   is_paid: true,
          //   transaction_id: response.razorpay_payment_id,
          //   product: cart,
          //   total_amount: discountedAmount,
          //   product_total: totalAmount,
          //   order_date: new Date(),
          // };
          createdOrder.data.is_paid = true;
          createdOrder.data.order_status = 'PROCESSING';

          // values.transaction_id = response.razorpay_payment_id;
          const newFormData1 = convertToFormData({
            createdOrder,
            featuredImage,
          });
          await api.put('/orders/' + createdOrder.data._id, newFormData1);

          localStorage.removeItem('SEHARI_CART');
          setOrderPlaced(false);
          history.push('/thank-you');
        },
        prefill: {
          name: `${values && values.first_name} ${values && values.last_name}`,
          email: values && values.email,
          contact: values && values.phone,
        },
        theme: {
          color: '#3399cc',
        },
      };
      var rzp1 = new window.Razorpay(options);
      rzp1.on('payment.failed', function (response) {
        alert('Payment Failed Please try again');
      });
      rzp1.open();
    }
    // if (values.payment_method === "ONLINE") {
    //   // addDonation(formData, cause[0].razorpay_link);
    //   const order = await axios.get(
    //     `https://sehariofficial.in/razorpay/pay.php?amount=${amount}`
    //   );
    //   // console.log(order.data);

    //   var options = {
    //     key: "rzp_live_r51hkhl415gSSd", // Enter the Key ID generated from the Dashboard
    //     amount: amount * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
    //     currency: "INR",
    //     name: "National NGO",
    //     description: "",
    //     image: "https://nationalngo.org/images/logo.png",
    //     order_id: order.data, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
    //     handler: async function (response) {
    //       const postData = {
    //         donor_name: donor_name ? donor_name : "",
    //         email: email ? email : "",
    //         phone: phone ? phone : "",
    //         pan: pan ? pan : "",
    //         volunteer: volunteer ? volunteer : "",
    //         amount: amount ? amount : "",
    //         city: city ? city : "",
    //         address: address ? address : "",
    //         pincode: pincode ? pincode : "",
    //         state: state ? state : "",
    //         message: message ? message : "",
    //         transaction_id: response.razorpay_payment_id,
    //         referrer: window.location.referrer ? window.location.referrer : "",
    //         page: window.location.href ? window.location.href : "",
    //       };

    //       await addDonation(postData);
    //       window.location.href = `/thank-you?amount=${postData.amount}&page=${window.location.href}`;
    //     },
    //     prefill: {
    //       name: donor_name,
    //       email: email,
    //       contact: phone,
    //     },
    //     theme: {
    //       color: "#3399cc",
    //     },
    //   };
    //   var rzp1 = new window.Razorpay(options);
    //   rzp1.on("payment.failed", function (response) {
    //     alert("Payment Failed Please try again");
    //   });
    //   rzp1.open();

    //   setIsLoading(false);
    // }
  };
  // console.log("CART", cart);
  return (
    <div>
      <Header />

      <div className="Checkout_section" id="accordion">
        {cart && cart.length > 0 ? (
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="user-actions">
                  <h3>
                    <i className="fa fa-file-o" aria-hidden="true" />
                    Have a Coupon Code?
                    <a
                      className={coupan ? 'Returning ' : 'Returning collapsed '}
                      href="#"
                      data-bs-toggle="collapse"
                      data-bs-target="#checkout_coupon"
                      aria-expanded="false"
                      onClick={() => {
                        setcoupan(!coupan);
                      }}
                    >
                      Click here to enter your code
                    </a>
                  </h3>
                  <div
                    id="checkout_coupon"
                    className={coupan ? 'collapse show' : 'collapse '}
                    data-parent="#accordion"
                  >
                    <CouponSection />
                  </div>
                </div>
              </div>
            </div>
            <div className="checkout_form">
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <Formik
                    initialValues={{
                      first_name: '',
                      last_name: '',
                      phone: '',
                      email: '',
                      address: '',
                      landmark: '',
                      city: '',
                      state: '',
                      pincode: '',
                      payment_method: '',
                    }}
                    validationSchema={Yup.object({
                      first_name: Yup.string().required('Required'),
                      last_name: Yup.string().required('Required'),
                      phone: Yup.string().required('Required'),
                      address: Yup.string().required('Required'),
                      city: Yup.string().required('Required'),
                      state: Yup.string().required('Required'),
                      pincode: Yup.string().required('Required'),
                    })}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                      setSubmitting(true);
                      await placeOrder(values);
                      resetForm();
                      setSubmitting(false);
                    }}
                  >
                    {(formik) => {
                      console.log(formik);
                      return (
                        <Form>
                          <h3>Billing Details</h3>
                          <div className="row">
                            <div className="col-md-6">
                              <TextInput
                                label="First Name"
                                name="first_name"
                                type="text"
                              />
                            </div>
                            <div className="col-md-6">
                              <TextInput
                                label="Last Name"
                                name="last_name"
                                type="text"
                              />
                            </div>
                            <div className="col-md-6">
                              <TextInput
                                label="Phone"
                                name="phone"
                                type="text"
                              />
                            </div>
                            <div className="col-md-6">
                              <TextInput
                                label="Email"
                                name="email"
                                type="text"
                              />
                            </div>
                            <div className="col-md-12">
                              <TextInput
                                label="Address"
                                name="address"
                                type="text"
                              />
                            </div>
                            <div className="col-md-6">
                              <TextInput
                                label="Landmark"
                                name="landmark"
                                type="text"
                              />
                            </div>
                            <div className="col-md-6">
                              <TextInput label="City" name="city" type="text" />
                            </div>
                            <div className="col-md-6">
                              <SelectBox label="State" name="state">
                                <option value=""> Please Select </option>
                                <option value="Andhra Pradesh">
                                  Andhra Pradesh
                                </option>
                                <option value="Andaman and Nicobar Islands">
                                  Andaman and Nicobar Islands
                                </option>
                                <option value="Arunachal Pradesh">
                                  Arunachal Pradesh
                                </option>
                                <option value="Assam">Assam</option>
                                <option value="Bihar">Bihar</option>
                                <option value="Chandigarh">Chandigarh</option>
                                <option value="Chhattisgarh">
                                  Chhattisgarh
                                </option>
                                <option value="Dadar and Nagar Haveli">
                                  Dadar and Nagar Haveli
                                </option>
                                <option value="Daman and Diu">
                                  Daman and Diu
                                </option>
                                <option value="Delhi">Delhi</option>
                                <option value="Lakshadweep">Lakshadweep</option>
                                <option value="Puducherry">Puducherry</option>
                                <option value="Goa">Goa</option>
                                <option value="Gujarat">Gujarat</option>
                                <option value="Haryana">Haryana</option>
                                <option value="Himachal Pradesh">
                                  Himachal Pradesh
                                </option>
                                <option value="Jammu and Kashmir">
                                  Jammu and Kashmir
                                </option>
                                <option value="Jharkhand">Jharkhand</option>
                                <option value="Karnataka">Karnataka</option>
                                <option value="Kerala">Kerala</option>
                                <option value="Madhya Pradesh">
                                  Madhya Pradesh
                                </option>
                                <option value="Maharashtra">Maharashtra</option>
                                <option value="Manipur">Manipur</option>
                                <option value="Meghalaya">Meghalaya</option>
                                <option value="Mizoram">Mizoram</option>
                                <option value="Nagaland">Nagaland</option>
                                <option value="Odisha">Odisha</option>
                                <option value="Punjab">Punjab</option>
                                <option value="Rajasthan">Rajasthan</option>
                                <option value="Sikkim">Sikkim</option>
                                <option value="Tamil Nadu">Tamil Nadu</option>
                                <option value="Telangana">Telangana</option>
                                <option value="Tripura">Tripura</option>
                                <option value="Uttar Pradesh">
                                  Uttar Pradesh
                                </option>
                                <option value="Uttarakhand">Uttarakhand</option>
                                <option value="West Bengal">West Bengal</option>
                              </SelectBox>
                            </div>
                            <div className="col-md-6">
                              <TextInput
                                label="Pincode"
                                name="pincode"
                                type="text"
                              />
                            </div>
                            <div className="col-md-12">
                              <div style={{ paddingTop: '10px' }}>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="exampleRadios"
                                    id="RAZORPAY"
                                    defaultValue="option1"
                                    defaultChecked
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setPaymentOption('RAZORPAY');
                                      }
                                    }}
                                    style={{
                                      width: '25px',
                                      height: '25px',
                                      padding: '0px',
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="RAZORPAY"
                                  >
                                    Razorpay ( Credit, Debit, UPI, NetBanking)
                                  </label>
                                </div>
                                {/* <div className="form-check mb-5">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="exampleRadios"
                                    id="COD"
                                    defaultValue="option1"
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setPaymentOption("CASH_ON_DELIVERY");
                                      }
                                    }}
                                    style={{
                                      width: "25px",
                                      height: "25px",
                                      padding: "0px",
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="COD"
                                  >
                                    Cash On Delivery
                                  </label>
                                </div> */}
                              </div>
                            </div>
                          </div>
                          <div className="order_button">
                            <button type="submit">
                              {' '}
                              {orderPlaced
                                ? 'Processing...'
                                : 'Proceed to Checkout'}{' '}
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
                <div className="col-lg-6 col-md-6">
                  <form action="#">
                    <h3>Your order</h3>
                    <div className="order_table table-responsive">
                      <table>
                        <thead>
                          <tr>
                            <th>Product</th>
                            <th>Description</th>
                          </tr>
                        </thead>
                        <tbody>
                          {cart &&
                            cart.map((item) => {
                              return (
                                <tr>
                                  <td>
                                    {item.product && item.product.name}
                                    <strong> × {item.quantity}</strong>
                                  </td>
                                  <td> ₹{item.sale_price * item.quantity}</td>
                                </tr>
                              );
                            })}
                        </tbody>
                        <tfoot>
                          <tr>
                            <th>Cart Subtotal</th>
                            <td>₹{total}</td>
                          </tr>
                          {discount && discount > 0 ? (
                            <tr>
                              <th>Discount</th>
                              <td>
                                <strong>-₹{discount}</strong>
                              </td>
                            </tr>
                          ) : (
                            ''
                          )}
                          <tr>
                            <th>Shipping</th>
                            <td>
                              <strong>₹{60}</strong>
                            </td>
                          </tr>
                          <tr className="order_total">
                            <th>Order Total</th>
                            <td>
                              <strong>₹{total + 60 - discount}</strong>
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="text-center">
                  <img
                    src={`/images/cart-empty.jpeg`}
                    style={{ width: '200px' }}
                  />
                  <p>
                    <strong> Your cart is empty </strong>
                  </p>
                  <p>
                    {' '}
                    Looks like you have not added anything to your cart. Go
                    ahead and explore products.
                  </p>
                  <p style={{ marginBottom: '30px' }}>
                    {' '}
                    <Link to="/" className="button">
                      {' '}
                      Go To Home
                    </Link>
                  </p>
                </div>
              </div>
            </div>
            <hr />
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Checkout;
