import {
  GET_HOMEPRODUCTS_STATED,
  GET_HOMEPRODUCTS,
  GET_HOMEPRODUCTS_ENDED,
  ADD_HOMEPRODUCT_STATED,
  ADD_HOMEPRODUCT,
  ADD_HOMEPRODUCT_ENDED,
  EDIT_HOMEPRODUCT_STATED,
  EDIT_HOMEPRODUCT,
  EDIT_HOMEPRODUCT_ENDED,
  GET_HOMEPRODUCT_STATED,
  GET_HOMEPRODUCT,
  GET_HOMEPRODUCT_ENDED,
  GET_ALL_HOMEPRODUCTS_STATED,
  GET_ALL_HOMEPRODUCTS,
  GET_ALL_HOMEPRODUCTS_ENDED
} from "../types/homeproduct_type";

const initialState = {
  homeproducts_loading: true,
  homeproducts: null,
  page: null,
  pages: null,
  total_homeproducts: 0,

  homeproduct: null,
  homeproduct_loading: null,

  loading: true,

  homeproduct_message: null,
  all_homeproducts: null,
  all_homeproducts_loading: null,
  add_homeproduct_loading: true,
  edit_homeproduct_loading: true
};

export const homeproduct_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_HOMEPRODUCTS_STATED:
      return {
        ...state,
        homeproducts: null,
        pages: null,
        page: null,
        total_homeproducts: 0,
        homeproducts_loading: true
      };
    case GET_HOMEPRODUCTS:
      return {
        ...state,
        homeproducts: payload.homeproducts,
        pages: payload.pages,
        page: payload.page,
        total_homeproducts: payload.count
      };
    case GET_HOMEPRODUCTS_ENDED:
      return {
        ...state,
        homeproducts_loading: false
      };
    case GET_ALL_HOMEPRODUCTS_STATED:
      return {
        ...state,
        all_homeproducts_loading: true,
        all_homeproducts: null
      };
    case GET_ALL_HOMEPRODUCTS:
      return {
        ...state,
        all_homeproducts: payload
      };
    case GET_ALL_HOMEPRODUCTS_ENDED:
      return {
        ...state,
        all_homeproducts_loading: false
      };

    case ADD_HOMEPRODUCT_STATED:
      return {
        ...state,
        homeproduct_message: null,
        add_homeproduct_loading: true
      };
    case ADD_HOMEPRODUCT:
      return {
        ...state,
        homeproduct_message: payload
      };
    case ADD_HOMEPRODUCT_ENDED:
      return {
        ...state,
        add_homeproduct_loading: false
      };
    case GET_HOMEPRODUCT_STATED:
      return {
        ...state,
        homeproduct: null,
        homeproduct_loading: true
      };
    case GET_HOMEPRODUCT:
      return {
        ...state,
        homeproduct: payload
      };
    case GET_HOMEPRODUCT_ENDED:
      return {
        ...state,
        homeproduct_loading: false
      };
    case EDIT_HOMEPRODUCT_STATED:
      return {
        ...state,
        homeproduct_message: null,
        edit_homeproduct_loading: true
      };
    case EDIT_HOMEPRODUCT:
      return {
        ...state,
        homeproduct_message: payload
      };
    case EDIT_HOMEPRODUCT_ENDED:
      return {
        ...state,
        edit_homeproduct_loading: false
      };

    default:
      return state;
  }
};
