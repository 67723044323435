export const GET_BLOGCATEGORYS_STATED = "GET_BLOGCATEGORYS_STATED";
export const GET_BLOGCATEGORYS = "GET_BLOGCATEGORYS";
export const GET_BLOGCATEGORYS_ENDED = "GET_BLOGCATEGORYS_ENDED";
export const ADD_BLOGCATEGORY_STATED = "ADD_BLOGCATEGORY_STARTED";
export const ADD_BLOGCATEGORY = "ADD_BLOGCATEGORY";
export const ADD_BLOGCATEGORY_ENDED = "ADD_BLOGCATEGORY_ENDED";
export const EDIT_BLOGCATEGORY_STATED = "EDIT_BLOGCATEGORY_STATED";
export const EDIT_BLOGCATEGORY = "EDIT_BLOGCATEGORY";
export const EDIT_BLOGCATEGORY_ENDED = "EDIT_BLOGCATEGORY_ENDED";
export const GET_BLOGCATEGORY = "GET_BLOGCATEGORY";
export const GET_BLOGCATEGORY_STATED = "GET_BLOGCATEGORY_STATED";
export const GET_BLOGCATEGORY_ENDED = "GET_BLOGCATEGORY_ENDED";
export const RESET_BLOGCATEGORY = "RESET_BLOGCATEGORY";
export const ERROR_BLOGCATEGORY = "ERROR_BLOGCATEGORY";
export const GET_ALL_BLOGCATEGORYS_STATED = "GET_ALL_BLOGCATEGORYS_STATED";
export const GET_ALL_BLOGCATEGORYS = "GET_ALL_BLOGCATEGORYS";
export const GET_ALL_BLOGCATEGORYS_ENDED = "GET_ALL_BLOGCATEGORYS_ENDED";
