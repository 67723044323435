import React from "react";
import { Link } from "react-router-dom";
import Breadcrum from "../../components/breadcrum/Breadcrum";
import Footer from "../../components/common/Footer";
import Header from "../../components/common/Header";

function ThankYou() {
  return (
    <div>
      <Header />
      <Breadcrum title={"Thank You"} />
      <div className="about_section">
        <div className="container">
          <div className="row ">
            <div className="col-6">
              <div className="about_thumb">
                <img src="assets/img/about/about1.jpg" alt />
              </div>
            </div>
            <div className="col-md-6">
              <div className="about_content">
                <h1>Thank You</h1>
                <p>
                  Thankyou for placing your order with us. You will receive an
                  email with all the details shortly.
                </p>
                <div className="cart_button checkout">
                  <Link to={"/"} className="active">
                    {" "}
                    Go To Home{" "}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default ThankYou;
