import { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  addOrder,
  getOrders,
  getOrder,
  editOrder,
  deleteOrder,
  getAllOrders,
} from '../../store/actions/order_action'
import _debounce from 'lodash/debounce'
// import { useSelectAllIndustry } from "./UseIndustry";

// Get All Data
export const useAllOrders = () => {
  const dispatch = useDispatch()
  const data = useSelector((state) => state.order)
  const searchOrder = async ({ phone, order_id }) => {
    await dispatch(getOrders({ order_id, phone }))
  }

  return [data, searchOrder]
}

// Get Single Data
export const useSingleOrder = (id) => {
  const dispatch = useDispatch()
  const data = useSelector((state) => state.order)
  useEffect(() => {
    dispatch(getOrder(id))
  }, [id])
  return [data]
}
// Add Data
export const useCreateOrder = () => {
  const dispatch = useDispatch()
  const data = useSelector((state) => state.order)
  const addData = async (data) => {
    await dispatch(addOrder(data))
  }
  return [data, addData]
}
export const useUpdateOrder = () => {
  const dispatch = useDispatch()
  // const data = useSelector((state) => state.order);
  const updateData = async (id, data) => {
    await dispatch(editOrder(id, data))
  }
  return [updateData]
}

export const useSelectAllOrder = () => {
  const dispatch = useDispatch()
  const [term, setTerm] = useState('')
  const [value, setValue] = useState('')
  const data = useSelector((state) => state.order)
  useEffect(() => {
    dispatch(getAllOrders({ term, value }))
  }, [term, value])
  return [data, setTerm, setValue]
}

export const useGetDropdownOptions = () => {
  //  const [client, setClientSearchField, setClientSearchValue] =
  // useSelectAllClient();

  const [dropdownOptions, setDropdownOptions] = useState({})
  // useEffect(() => {
  //   if (industry && industry.all_industrys) {
  //     const newData = industry.all_industrys.map((item) => {
  //       return { label: item.name, value: item.name };
  //     });
  //     setDropdownOptions({ ...dropdownOptions, industry: newData });
  //   }
  // }, [industry]);

  return [
    dropdownOptions,
    // setClientSearchField, setClientSearchValue
  ]
}
