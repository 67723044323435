export const GET_HOMEPRODUCTS_STATED = "GET_HOMEPRODUCTS_STATED";
export const GET_HOMEPRODUCTS = "GET_HOMEPRODUCTS";
export const GET_HOMEPRODUCTS_ENDED = "GET_HOMEPRODUCTS_ENDED";
export const ADD_HOMEPRODUCT_STATED = "ADD_HOMEPRODUCT_STARTED";
export const ADD_HOMEPRODUCT = "ADD_HOMEPRODUCT";
export const ADD_HOMEPRODUCT_ENDED = "ADD_HOMEPRODUCT_ENDED";
export const EDIT_HOMEPRODUCT_STATED = "EDIT_HOMEPRODUCT_STATED";
export const EDIT_HOMEPRODUCT = "EDIT_HOMEPRODUCT";
export const EDIT_HOMEPRODUCT_ENDED = "EDIT_HOMEPRODUCT_ENDED";
export const GET_HOMEPRODUCT = "GET_HOMEPRODUCT";
export const GET_HOMEPRODUCT_STATED = "GET_HOMEPRODUCT_STATED";
export const GET_HOMEPRODUCT_ENDED = "GET_HOMEPRODUCT_ENDED";
export const RESET_HOMEPRODUCT = "RESET_HOMEPRODUCT";
export const ERROR_HOMEPRODUCT = "ERROR_HOMEPRODUCT";
export const GET_ALL_HOMEPRODUCTS_STATED = "GET_ALL_HOMEPRODUCTS_STATED";
export const GET_ALL_HOMEPRODUCTS = "GET_ALL_HOMEPRODUCTS";
export const GET_ALL_HOMEPRODUCTS_ENDED = "GET_ALL_HOMEPRODUCTS_ENDED";
