export const GET_RETURNREQUESTS_STATED = "GET_RETURNREQUESTS_STATED";
export const GET_RETURNREQUESTS = "GET_RETURNREQUESTS";
export const GET_RETURNREQUESTS_ENDED = "GET_RETURNREQUESTS_ENDED";
export const ADD_RETURNREQUEST_STATED = "ADD_RETURNREQUEST_STARTED";
export const ADD_RETURNREQUEST = "ADD_RETURNREQUEST";
export const ADD_RETURNREQUEST_ENDED = "ADD_RETURNREQUEST_ENDED";
export const EDIT_RETURNREQUEST_STATED = "EDIT_RETURNREQUEST_STATED";
export const EDIT_RETURNREQUEST = "EDIT_RETURNREQUEST";
export const EDIT_RETURNREQUEST_ENDED = "EDIT_RETURNREQUEST_ENDED";
export const GET_RETURNREQUEST = "GET_RETURNREQUEST";
export const GET_RETURNREQUEST_STATED = "GET_RETURNREQUEST_STATED";
export const GET_RETURNREQUEST_ENDED = "GET_RETURNREQUEST_ENDED";
export const RESET_RETURNREQUEST = "RESET_RETURNREQUEST";
export const ERROR_RETURNREQUEST = "ERROR_RETURNREQUEST";
export const GET_ALL_RETURNREQUESTS_STATED = "GET_ALL_RETURNREQUESTS_STATED";
export const GET_ALL_RETURNREQUESTS = "GET_ALL_RETURNREQUESTS";
export const GET_ALL_RETURNREQUESTS_ENDED = "GET_ALL_RETURNREQUESTS_ENDED";
