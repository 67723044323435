import React from 'react'
import { connect } from 'react-redux'
import Slider from 'react-slick'
export const Testimonial = (props) => {
  const testimonial = {
    slidesToShow: 3,
    slidesToScroll: 3,

    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  }
  return (
    <div>
      <section className="main-testi ptb-0">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div>
                <div className="">
                  <Slider {...testimonial}>
                    <div className="main-testi-box">
                      <img src="/images/testimonials/client-1.jpg" />
                    </div>
                    <div className="main-testi-box">
                      <img src="/images/testimonials/client-2.jpg" />
                    </div>
                    <div className="main-testi-box">
                      <img src="/images/testimonials/client-3.jpg" />
                    </div>
                    <div className="main-testi-box">
                      <img src="/images/testimonials/client-4.jpg" />
                    </div>
                    <div className="main-testi-box">
                      <img src="/images/testimonials/client-5.jpg" />
                    </div>
                    <div className="main-testi-box">
                      <img src="/images/testimonials/client-6.jpg" />
                    </div>
                    <div className="main-testi-box">
                      <img src="/images/testimonials/client-7.jpg" />
                    </div>
                    <div className="main-testi-box">
                      <img src="/images/testimonials/client-8.jpg" />
                    </div>
                    <div className="main-testi-box">
                      <img src="/images/testimonials/client-9.jpg" />
                    </div>
                    <div className="main-testi-box">
                      <img src="/images/testimonials/client-10.jpg" />
                    </div>
                    <div className="main-testi-box">
                      <img src="/images/testimonials/client-11.jpg" />
                    </div>
                    <div className="main-testi-box">
                      <img src="/images/testimonials/client-12.jpg" />
                    </div>
                    <div className="main-testi-box">
                      <img src="/images/testimonials/client-13.jpg" />
                    </div>
                    <div className="main-testi-box">
                      <img src="/images/testimonials/client-14.jpg" />
                    </div>
                    <div className="main-testi-box">
                      <img src="/images/testimonials/client-15.jpg" />
                    </div>
                    <div className="main-testi-box">
                      <img src="/images/testimonials/client-16.jpg" />
                    </div>
                    <div className="main-testi-box">
                      <img src="/images/testimonials/client-17.jpg" />
                    </div>
                    <div className="main-testi-box">
                      <img src="/images/testimonials/client-18.jpg" />
                    </div>
                    <div className="main-testi-box">
                      <img src="/images/testimonials/client-19.jpg" />
                    </div>
                    <div className="main-testi-box">
                      <img src="/images/testimonials/client-20.jpg" />
                    </div>
                    <div className="main-testi-box">
                      <img src="/images/testimonials/client-21.jpg" />
                    </div>
                    <div className="main-testi-box">
                      <img src="/images/testimonials/client-22.jpg" />
                    </div>
                    <div className="main-testi-box">
                      <img src="/images/testimonials/client-23.jpg" />
                    </div>
                    <div className="main-testi-box">
                      <img src="/images/testimonials/client-24.jpg" />
                    </div>
                    <div className="main-testi-box">
                      <img src="/images/testimonials/client-25.jpg" />
                    </div>
                    <div className="main-testi-box">
                      <img src="/images/testimonials/client-26.jpg" />
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Testimonial)
