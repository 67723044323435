import { Form, Formik } from 'formik'
import React from 'react'
import { connect } from 'react-redux'
import Breadcrum from '../../components/breadcrum/Breadcrum'
import Footer from '../../components/common/Footer'
import Header from '../../components/common/Header'
import { TextInput } from '../../components/Form/Form'
import * as Yup from 'yup'
import { useAllOrders } from '../../shared/hooks/UseOrder'
import { useState } from 'react'

export const SingleOrder = (props) => {
  const [order, setOrder] = useState(null)
  const [data, searchOrder] = useAllOrders()
  return (
    <div>
      <Header />
      <Breadcrum title={'Track Order '} />
      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-8 mx-auto">
              <div className="order-id">
                <div className="order-flex">
                  <div className="order-heading">
                    <Formik
                      initialValues={{
                        phone: '',
                        order_id: '',
                      }}
                      validationSchema={Yup.object({
                        phone: Yup.string().required('Required'),
                        order_id: Yup.string().required('Required'),
                      })}
                      onSubmit={async (
                        values,
                        { setSubmitting, resetForm },
                      ) => {
                        setSubmitting(true)
                        await searchOrder({
                          phone: values.phone,
                          order_id: values.order_id,
                        })
                        resetForm()
                        setSubmitting(false)
                      }}
                    >
                      {(formik) => {
                        console.log(formik)
                        return (
                          <Form>
                            <div className="row">
                              <div className="col-md-12">
                                <TextInput
                                  label="Order ID"
                                  name="order_id"
                                  type="text"
                                />
                              </div>
                              <div className="col-md-12 ">
                                <TextInput
                                  label="Phone"
                                  name="phone"
                                  type="text"
                                />
                              </div>
                              <button className="btn btn-status m-2">
                                Track Order
                              </button>
                            </div>
                          </Form>
                        )
                      }}
                    </Formik>
                  </div>
                </div>
                {order && (
                  <div>
                    <hr></hr>
                    <div className="main-order-flex">
                      <div className="order-name-flex">
                        <div className="order-image">
                          <img src="https://sehari-api.webgrowsolutions.com/uploads/gallery-1657697222582.jpg"></img>
                        </div>
                        <div className="order-cate">
                          <h3>Nosepin with single nose</h3>
                        </div>
                      </div>

                      <div className="order-total">
                        <h3>₹2599.00</h3>
                      </div>
                    </div>
                    <hr></hr>
                    <div className="payment-flex">
                      <div className="payment-status">
                        <h3>Payment Mode: Cash</h3>
                      </div>
                      <div className="delivery">
                        <h3>Delivery</h3>
                        <h5>Address</h5>
                        <p>128/619 Y Block</p>
                        <p>Kidwai Nagar </p>
                        <p>Kanpur UP 208011</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(SingleOrder)
