import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addCoupon,
  getCoupons,
  getCoupon,
  editCoupon,
  deleteCoupon,
  getAllCoupons,
  applyCoupon,
} from "../../store/actions/coupon_action";
import _debounce from "lodash/debounce";
// import { useSelectAllIndustry } from "./UseIndustry";
import api from "../../domain/api";
import { useGetCart } from "./UseCart";
// Get All Data
export const useAllCoupons = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.coupon);
  const [pageNumber, setPageNumber] = useState(1);
  const [deleteEntry, setDeleteEntry] = useState(null);

  useEffect(() => {
    if (deleteEntry) {
      dispatch(deleteCoupon(deleteEntry));
    }
    allQuery();
  }, [deleteEntry, pageNumber, window.location.search]);
  const allQuery = useCallback(
    _debounce(() => {
      dispatch(
        getCoupons({
          pageNumber,
        })
      );
    }, 1000),
    []
  );

  useEffect(() => {
    setPageNumber(1);
  }, [window.location.search]);

  const deleteBtnClicked = async (id) => {
    setDeleteEntry(id);
  };

  return [data, setPageNumber, deleteBtnClicked];
};

// Get Single Data
export const useSingleCoupon = (id) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.coupon);
  useEffect(() => {
    dispatch(getCoupon(id));
  }, [id]);
  return [data];
};
// Add Data
export const useCreateCoupon = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.coupon);
  const addData = async (data) => {
    await dispatch(addCoupon(data));
  };
  return [data, addData];
};
export const useUpdateCoupon = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.coupon);
  const updateData = async (id, data) => {
    await dispatch(editCoupon(id, data));
  };
  return [updateData];
};

export const useApplyCoupon = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.coupon);
  const updateData = async (code) => {
    if (code) {
      await dispatch(applyCoupon(code));
    }
  };
  const [cart, total] = useGetCart();
  const { coupon_loading, coupon } = data;
  const [discount, setDiscount] = useState(0);
  const [error, setError] = useState(false);

  useEffect(() => {
    let errorDiscount = false;
    if (coupon) {
      if (total > coupon.min_cart_value) {
        let discount = 0;
        if (coupon.discount_type == "PERCENTAGE") {
          let percentage_discount = parseFloat(
            (total * coupon.discount) / 100
          ).toFixed(2);

          setDiscount(
            percentage_discount < coupon.max_discount
              ? percentage_discount
              : coupon.max_discount
          );
        } else {
          setDiscount(coupon.discount);
        }
        errorDiscount = false;
      } else {
        errorDiscount = true;
      }
      setError(errorDiscount);
    }
  }, [coupon]);
  return [data, updateData, discount, error];
};

export const useSelectAllCoupon = () => {
  const dispatch = useDispatch();
  const [term, setTerm] = useState("");
  const [value, setValue] = useState("");
  const data = useSelector((state) => state.coupon);
  const [cart, total] = useGetCart();
  const { coupon_loading, coupon } = data;
  const [discount, setDiscount] = useState(0);
  const [error, setError] = useState(false);

  useEffect(() => {
    dispatch(getAllCoupons({ term, value }));
  }, [term, value]);
  useEffect(() => {
    let errorDiscount = false;
    if (coupon) {
      if (total > coupon.min_cart_value) {
        let discount = 0;
        if (coupon.discount_type == "PERCENTAGE") {
          let percentage_discount = parseFloat(
            (total * discount) / 100
          ).toFixed(2);
          setDiscount(
            percentage_discount < coupon.max_discount
              ? percentage_discount
              : coupon.max_discount
          );
        } else {
          setDiscount(coupon.discount);
        }
        errorDiscount(false);
      } else {
        errorDiscount = true;
      }
      setError(errorDiscount);
    }
  }, [coupon]);

  return [data, setTerm, setValue, discount, error];
};

export const useGetDropdownOptions = () => {
  //  const [client, setClientSearchField, setClientSearchValue] =
  // useSelectAllClient();

  const [dropdownOptions, setDropdownOptions] = useState({});
  // useEffect(() => {
  //   if (industry && industry.all_industrys) {
  //     const newData = industry.all_industrys.map((item) => {
  //       return { label: item.name, value: item.name };
  //     });
  //     setDropdownOptions({ ...dropdownOptions, industry: newData });
  //   }
  // }, [industry]);

  return [
    dropdownOptions,
    // setClientSearchField, setClientSearchValue
  ];
};
